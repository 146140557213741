import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { ContentfulDish } from '@queryTypes/contentfulDish';
import { Layout } from '@base';

const SingleDish = styled.a`
  display: block;
  border: solid 1px #f0f0f0;
  color: #f0f0f0;
  padding: 2rem;
  text-align: center;
  margin: 0.5rem;
`;

const DishContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem;
  background: #333;
`;

type Props = {
  data: {
    contentfulDish: ContentfulDish;
  };
};

export default ({ data }: Props) => {
  const { contentfulDish } = data;

  return (
    <Layout>
      <DishContainer>
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta name="description" content={contentfulDish.description} />
          <title>{contentfulDish.title}</title>
        </Helmet>
        <SingleDish>
          <h1>{contentfulDish.title}</h1>
          <Img critical title={contentfulDish.image.title} fixed={contentfulDish.image.fixed} />
          <p>{contentfulDish.description}</p>
          <pre>{contentfulDish.price}</pre>
        </SingleDish>
      </DishContainer>
    </Layout>
  );
};

export const query = graphql`
  # query getSingleDish($slug: String) {
  #   contentfulDish(slug: { eq: $slug }) {
  query getSingleDish($id: String) {
    contentfulDish(id: { eq: $id }) {
      id
      slug
      title
      description
      price
      image {
        title
        fixed(width: 320) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
  }
`;
